import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Navbar,
  Nav,
  NavDropdown,
} from "react-bootstrap";
import { Form } from "antd";
import moment from "moment";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import ConsultasAPI from "../../../shared/helpers/consultasAPI";
import TablasExtractos from "./TablasExtractos";
import { Link } from "react-router-dom";
import { FcCalendar } from "react-icons/fc";
import "bootstrap/dist/css/bootstrap.min.css";
const FormItem = Form.Item;

function agregarCeros(numero) {
  const numeroString = numero.toString(); // Convertir el número a string
  const cerosFaltantes = 4 - numeroString.length; // Calcular la cantidad de ceros faltantes
  if (cerosFaltantes > 0) {
    const ceros = "0".repeat(cerosFaltantes); // Generar una cadena de ceros
    return ceros + numeroString; // Concatenar los ceros y el número
  }
  return numeroString; // Devolver el número sin cambios si ya tiene 4 dígitos o más
}

const SubirExtracto = () => {
  const URL_EXTRACTOREGISTRO = window.API_ROUTES.EXTRACTOREGISTRO;
  const URL_TIPOSORTEO = window.API_ROUTES.TIPOSORTEO;
  const URL_EXTRACTO = window.API_ROUTES.EXTRACTOS;
  const [form] = Form.useForm();
  const [fecha, setFecha] = useState({
    fechaMuestra: moment().format("DD/MM/YYYY"),
    fechaComparar: moment().format("YYYY-MM-DD"),
  });
  const [datosTablas, setDatosTablas] = useState([]);
  const [tablas, setTablas] = useState();
  const [tipos, setTipos] = useState([]);
  const [cantidad, setCantidad] = useState();

  useEffect(() => {
    cargarTabla();
  }, [fecha]);

  const cargarTabla = () => {
    // Traemos el extracto del back, seleccionando una fecha
    ConsultasAPI.ObtenerObjeto(
      URL_EXTRACTO,
      "?fecha_sorteo=" + fecha.fechaComparar
    ).then((response) => {
      setCantidad(response.data.length);
      let datosTablas = [];
      let contador = 0;
      let cantidad = response.data.length;
      if (response.data.length > 0) {
        response.data.forEach((ext) => {
          let datosExtracto = [];
          ConsultasAPI.ObtenerObjeto(URL_TIPOSORTEO, ext.tipo).then(
            (response) => {
              let hora = response.data.hora_sorteo;
              ConsultasAPI.ObtenerObjeto(
                URL_EXTRACTOREGISTRO,
                "?id=" + ext.id
              ).then((response) => {
                // Con el extracto obtenido traemos los registros, con el id extracto
                if (response.data.length > 0) {
                  for (let i = 10; i > 0; i--) {
                    datosExtracto.push({
                      key: i,
                      numPremio1_10: `${11 - i}° Premio`,
                      numero1_10: agregarCeros(response.data[i + 9].numero),
                      numPremio11_20: `${21 - i}° Premio`,
                      numero11_20: agregarCeros(response.data[i - 1].numero),
                      tipo: ext.tipo_detalle,
                      tipoid: ext.tipo,
                      sorteo: ext.sorteo,
                      fecha: fecha.fechaMuestra,
                      hora: hora,
                    });
                  }
                  datosTablas.push(datosExtracto);
                  // Ordena los datos de la tabla segun el tipo de sorteo
                  datosTablas.sort((a, b) => {
                    if (a[0].tipoid < b[0].tipoid) {
                      return -1;
                    } else if (a[0].tipoid > b[0].tipoid) {
                      return 1;
                    }
                    return 0;
                  });
                  contador++;
                  if (contador == cantidad) {
                    setDatosTablas(datosTablas);
                  }
                } else {
                  setDatosTablas(null); // Cuando existe alguna falla con los registros del extracto
                }
              });
            }
          );
        });
      } else {
        setDatosTablas(null);
      }
    });
  };
  const handleFechaChange = (momentDate) => {
    const fechaMuestra = momentDate.format("DD/MM/YYYY");
    const fechaComparar = momentDate.format("YYYY-MM-DD");
    setFecha({ fechaMuestra: fechaMuestra, fechaComparar: fechaComparar });
  };
  return (
    <div className="mainSection my-4">
      <Row className="d-flex justify-content-Start row-cols-2">
        {/* Columna Lateral con menu a la pagina de la caja */}
        <Col md={2} sm={12} xs={12} style={{ marginLeft: "2%" }}>
          <Card className="custom-card">
          <Navbar collapseOnSelect expand="sm" >
                            <Navbar.Toggle aria-controls="navbarScroll" />
                            <Navbar.Collapse id="navbarScroll">
                                <Nav className="flex-column">
                                    <Nav.Link as={Link} to="https://www.cajapopular.gov.ar/plantilla.php" className="link-item letraCaja">Inicio</Nav.Link>
                                    <Nav.Link as={Link} to="/" className="link-item letraCaja" style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center'}}>Quiniela
                                        <NavDropdown id="navbarScrollingDropdown" drop="end" >
                                            <NavDropdown.Item as={Link} to="https://www.cajapopular.gov.ar/juegos/24/resea-historica.html" className="link-item letraCaja px-5">Liquidación de Premios</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to="https://www.cajapopular.gov.ar/juegos/55/horarios-de-sorteos.html" className="link-item letraCaja">Horarios de Sorteos</NavDropdown.Item>
                                        </NavDropdown>
                                    </Nav.Link>

                                    <Nav.Link as={Link} to="https://www.cajapopular.gov.ar/plantilla_nota.php?id=24" className="link-item letraCaja" style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center'}}>Casino
                                        <NavDropdown id="navbarScrollingDropdown" drop="end"
                                        >
                                            <NavDropdown.Item as={Link} to="https://www.cajapopular.gov.ar/juegos/24/resea-historica.html" className="link-item letraCaja">Reseña Histórica</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to="https://www.cajapopular.gov.ar/juegos/26/actualidad.html" className="link-item letraCaja">Actualidad</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to="https://www.cajapopular.gov.ar/juegos/27/servicios.html" className="link-item letraCaja">Servicios</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to="https://www.cajapopular.gov.ar/juegos/28/telefonos.html" className="link-item letraCaja">Teléfonos</NavDropdown.Item>
                                        </NavDropdown>
                                    </Nav.Link>
                                    <Nav.Link as={Link} to="https://www.cajapopular.gov.ar/plantilla_nota.php?id=29" className="link-item letraCaja" style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center'}}>Hipódromo
                                        <NavDropdown id="navbarScrollingDropdown" drop="end">
                                            <NavDropdown.Item as={Link} to="https://www.cajapopular.gov.ar/juegos/29/resea-historica.html" className="link-item letraCaja">Reseña Histórica</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to="https://www.cajapopular.gov.ar/juegos/25/servicios.html" className="link-item letraCaja">Servicios</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to="https://www.cajapopular.gov.ar/juegos/30/museo.html" className="link-item letraCaja">Museo</NavDropdown.Item>
                                        </NavDropdown>
                                    </Nav.Link>
                                    <Nav.Link as={Link} to="https://www.cajapopular.gov.ar/juegos/64/requisitos-organizacion-bingos.html" className="link-item letraCaja">Requisitos Org. Bingo</Nav.Link>
                                    <Nav.Link as={Link} to="/" className="link-item letraCaja">Resultados Quiniela</Nav.Link>
                                    <Nav.Link as={Link} to="https://www.cajapopular.gov.ar/sorteos_tuqui.php" className="link-item letraCaja">Resultados Tuqui 10</Nav.Link>
                                </Nav>
                            </Navbar.Collapse>
                        </Navbar>
          </Card>
        </Col>
        {/* Columna con las cards de sorteos */}
        <Col md={9} sm={12} xs={12} className="justify-content-center">
          <Row className="justify-content-center">
            <Col md={6}>
              <div className="d-flex flex-column align-items-center justify-content-center">
                <label className="letraCaja">Ver resultados dia:</label>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <FcCalendar size={50} />
                  <div>
                    <Datetime
                      className="custom-datetime"
                      timeFormat={false}
                      dateFormat="DD/MM/YYYY"
                      inputProps={{
                        readOnly: true,
                        placeholder: "Buscar",
                      }}
                      value={fecha.fechaMuestra}
                      onChange={handleFechaChange}
                      closeOnSelect={true}
                    />
                  </div>
                </div>
                <div className="text-center">
                  {datosTablas ? (
                    <h3
                      className="letraCajaResultado"
                      style={{ color: "#008285" }}
                    >
                      Resultados{" "}
                      {moment(fecha.fechaMuestra, "DD/MM/YYYY").format("DD")} de{" "}
                      {moment(fecha.fechaMuestra, "DD/MM/YYYY").format("MMMM").charAt(0).toUpperCase() + moment(fecha.fechaMuestra, "DD/MM/YYYY").format("MMMM").slice(1) }{" "}
                      de{" "}
                      {moment(fecha.fechaMuestra, "DD/MM/YYYY").format("YYYY")}{" "}
                    </h3>
                  ) : null}
                </div>
              </div>
            </Col>
          </Row>
          <Row className="row-tabla p-3">
            {datosTablas && datosTablas.length > 0 ? (
              datosTablas.map((tabla, index) => (
                <Col md={5} key={index}>
                  <TablasExtractos datos={tabla} />
                </Col>
              ))
            ) : (
              <div className="row-tabla">
                <h3 className="letraCaja" style={{ fontSize: "150%", textAlign: 'center'}}>
                  No se realizaron sorteos en la fecha seleccionada
                </h3>
              </div>
            )}
          </Row>
        </Col>
      </Row>
    </div>
  );
  
};
export default SubirExtracto;
