import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { Container, Navbar, Row, Col } from "react-bootstrap";

const Menu = () => {
  const fechaActual = moment();
  const diaSemana = fechaActual
    .format("dddd")
    .replace(/^\w/, (letra) => letra.toUpperCase());
  const diaMes = fechaActual.format("DD");
  const mes = fechaActual.format("MMMM");
  const mesCapitalizado = mes.charAt(0).toUpperCase() + mes.slice(1);
  const año = fechaActual.format("YYYY");
  return (
    <>
      <Container>
        <Navbar expand="lg" className="bg-body-tertiary bg-transparent">
          <Container fluid>
            <Row
              className="container-fluid"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: '15px'
              }}
            >
              <Col>
                <Navbar.Brand as={Link} to="/">
                  <img
                    src={require("../../img/logo-cpa-sf.png")}
                    alt="Logo Caja Popular de Ahorros"
                    style={{
                      /* relacion de aspecto w=2.35h */
                      width: "235px",
                      height: "100px",
                    }}
                    className="mx-auto d-block" // Esta clase centrará el logo horizontalmente.
                  ></img>
                </Navbar.Brand>
              </Col>
              <Col>
                <h5 className="text-center letraFecha text-sm">{`${diaSemana} ${diaMes} de ${mesCapitalizado} de ${año} - Tucumán - Argentina`}</h5>
              </Col>
            </Row>
          </Container>
        </Navbar>
      </Container>
      <div
        className="d-flex justify-content-center"
        style={{
          display: "flex",
          flexDirection: "column",
          background: "#f48120"
        }}
      >
        <img
          src={require("../../img/slider-quiniela.png")}
          alt="Logo Caja Popular de Ahorros"
          style={{
            display: "block",
            margin: "0 auto",
            width: "100%",
            marginTop: "0%",
            marginBottom: "0%",
          }}
        />
      </div>
    </>
  );
};

export default Menu;
