import React from 'react';
import {
    Row,
    Col
} from "react-bootstrap";


const Footer = () => {
    return (
        // <footer className="footer py" style={{ flexDirection: "column", borderTop: '20px solid #F15E21' }}>
        <footer className="footer py" style={{ flexDirection: "column"}}>
            <div>
                <p className="text-center letraCaja m-0" > La información aquí proporcionada lo es solo a título informativo, y la misma no releva de la obligación de consultar el extracto oficial correspondiente a cada sorteo
                    a los fines del pago de los premios. </p>
            </div>
            <Row className='container-fluid' style={{ backgroundColor: "#008185", marginTop: '10px', padding: '10px' }}>
                <Col  sm={4} className="letraFooter" style={{ display: "flex", alignItems: "center", justifyContent: "center", color: "white", padding: '0 px', margin: '0px' }}>
                    <div>
                        <h3 className="text-center  m-0">JUEGO RESPONSABLE</h3>
                        <p className="text-center  m-0">EL JUEGO COMPULSIVO ES PERJUDICIAL PARA LA SALUD.</p>
                    </div>
                </Col>
                <Col sm={4} style={{ display: "flex", justifyContent: "center" }}>
                    <img
                        src={require("../../img/juego-responsable.png")}
                        alt="Logo cpa"
                        style={{ width: "152px" , height: "150px"}}
                    />
                </Col>
                <Col sm={4} className="letraFooter" style={{ display: "flex", alignItems: "center", justifyContent: "center", color: "white"}}>
                    <div>
                        <h5 className="text-center  m-0">Si necesitás ayuda llamá al</h5>
                        <h2 className="text-center  m-0">0800 555 7678</h2>
                        <h5 className="text-center  m-0">LEY Nº 8.986</h5>
                    </div>
                </Col>
            </Row>

            <Row className='container-fluid' style={{marginTop: '10px', marginBottom: '15px'}}>
                <Col style={{ display: "flex", justifyContent: "center" }}>
                    {/* relacion de aspecto w=1,35h */}
                    <img
                        src={require("../../img/logo-quiniela.png")}
                        alt="Logo quiniela"
                        style={{ width: "200px", height: "148px" }}
                    />
                </Col>
                <Col style={{ display: "flex", justifyContent: "center" }}>
                    {/* relacion de aspecto h=0.57w */}
                    <img
                        src={require("../../img/iram.png")}
                        alt="Logo iram"
                        style={{ width: "85px", height: "140px" }}
                    />
                </Col>
                <Col style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {/* relacion de aspecto w=3,26h */}
                    <img
                        src={require("../../img/logo-cpa-footer.png")}
                        alt="Logo cpa"
                        style={{ width: "250px", height: "77px" }}
                    />
                </Col>
            </Row>
        </footer>
    );
};

export default Footer;
