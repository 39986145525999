import React from 'react';
import { Container,} from "react-bootstrap";

const Error = () => {
    return (
        <Container className="mainSection">
            <h1 className="text-center m-3"> Pagina no encontrada</h1>
        </Container>
    );
};

export default Error;