import React, { Fragment } from "react";
import Principal from "./Principal";

const Inicio = () => {

  return (
    <Fragment>
        {<Principal/>}
    </Fragment>
  );
};

export default Inicio;
